<template>
  <div>
    <el-col :sm="12" :lg="6">
      <el-result icon="success" title="成功提示" subTitle="请根据提示进行操作">
        <template slot="extra">
          <el-button type="primary" size="medium">签署</el-button>
        </template>
      </el-result>
    </el-col>
  </div>
</template>

<script>
export default {

  data() {
    return {
        signFlowId:''
    };
  },

  mounted() {
    this.signFlowId = this.$rou
  },

  methods: {
     getUserUrl(row) {
      let data = {
        signFlowId: row.signFlowId,
        psnAccount :"93795f2ad2244f449d6168151e18be35",
        orgId:'2c4ed4c5690c476d9ebfde1efa034ca1'
      };
      
      this.$post("user/esign/signUrl", data).then((res) => {
        
      });
    },
  },
};
</script>